














import { Component, Vue } from "vue-property-decorator";
import { NewsApi, News } from "@/store/news-api";
import CMarkdown from "@/common/components/CMarkdownWrap.vue";

@Component({ components: { CMarkdown } })
export default class NewsDetail extends Vue {
    isMounted = false;
    newsStore = new NewsApi();
    newsItem: News = {} as News;
    mounted() {
        this.isMounted = false;
        this.newsStore
            .get({ newsId: +this.$route.params.id })
            .then((res) => (this.newsItem = res.data))
            .then(() => (this.isMounted = true));
    }
}
