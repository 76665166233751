




import { Component, Vue, Prop } from "vue-property-decorator";
import marked from "marked";

@Component({})
export default class CMarkdownWrap extends Vue {
    @Prop({ type: String }) markdown!: string;
    get compiledMarkdown() {
        return marked(this.markdown, { sanitize: false });
    }
}
