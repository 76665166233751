































import { Component, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import { ProfileModule } from "@/store/profile-module";
import { NewsApi, News } from "@/store/news-api";
import NewsForm from "./NewsForm.vue";
import CDeleteConfirm from "@/common/components/CDeleteConfirm.vue";
import { toKebabCase } from "@/common/utils/stringUtils";

@Component({ components: { CDeleteConfirm, NewsForm } })
export default class NewsList extends Vue {
    profileStore = getModule(ProfileModule);
    newsStore = new NewsApi();
    newsItems: News[] = [];
    toKebabCase = toKebabCase;

    get isAdmin() {
        return this.profileStore.profile.admin;
    }

    mounted() {
        this.get();
    }

    get() {
        return this.newsStore.getAll().then((res) => (this.newsItems = res.data));
    }

    upsertItem(item: News) {
        (this.$refs.dialog as any).open(item);
    }

    confirmDelete(item: News) {
        (this.$refs.delete as CDeleteConfirm).open(item);
    }

    deleteConfirmed(item: News) {
        this.newsStore.delete({ newsId: item.id }).then(() => this.get());
    }
}
