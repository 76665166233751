

























import { Component, Vue } from "vue-property-decorator";
import { GalleryApi, Gallery } from "@/store/gallery-api";

@Component({ components: {} })
export default class GalleryDetail extends Vue {
    galleryStore = new GalleryApi();
    gallery: Gallery = {} as Gallery;

    mounted() {
        this.galleryStore.get({ galleryId: +this.$route.params.id }).then((res) => (this.gallery = res.data));
    }
}
