import { axios } from "./axios";
import FileUtils from "@/common/utils/fileUtils";

export class GalleryApi {
    getAll() {
        return axios.get<Gallery[]>(`/gallery`);
    }

    get({ galleryId }: { galleryId: number }) {
        if (!galleryId) {
            return Promise.resolve({ data: {} as Gallery });
        }
        return axios.get<Gallery>(`/gallery/${galleryId}`);
    }

    save({ gallery }: { gallery: Gallery }) {
        const formData = FileUtils.objectToFormData(gallery);
        if (!!gallery.id && gallery.id > -1) {
            return axios.put("/gallery", formData);
        } else {
            return axios.post("/gallery", formData);
        }
    }

    delete({ galleryId }: { galleryId: number }) {
        return axios.delete(`/gallery/${galleryId}`);
    }
}

export interface Gallery {
    id: number;
    title: string;
    mediaUri: string;
    items: string[];
}
