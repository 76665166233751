























import { Component, Vue } from "vue-property-decorator";
import { GalleryApi, Gallery } from "@/store/gallery-api";
import { toKebabCase } from "@/common/utils/stringUtils";

@Component({ components: {} })
export default class GalleryList extends Vue {
    galleryStore = new GalleryApi();
    galleries: Gallery[] = [];
    toKebabCase = toKebabCase;

    mounted() {
        this.galleryStore.getAll().then((res) => (this.galleries = res.data));
    }
}
