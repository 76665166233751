import { axios } from "./axios";
import FileUtils from "@/common/utils/fileUtils";

export class NewsApi {
    getAll() {
        return axios.get<News[]>(`/news`);
    }

    get({ newsId }: { newsId: number }) {
        if (!newsId) {
            return Promise.resolve({ data: {} as News });
        }
        return axios.get<News>(`/news/${newsId}`);
    }

    save({ news }: { news: News }) {
        const formData = FileUtils.objectToFormData(news);
        if (!!news.id && news.id > -1) {
            return axios.put("/news", formData);
        } else {
            return axios.post("/news", formData);
        }
    }

    delete({ newsId }: { newsId: number }) {
        return axios.delete(`/news/${newsId}`);
    }
}

export interface News {
    id: number;
    title: string;
    details: string;
    mediaUri: string;
    imageUpload: Blob;
}
