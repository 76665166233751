






































import { Component, Vue, Emit } from "vue-property-decorator";
import { NewsApi, News } from "@/store/news-api";
import { required, maxLength } from "@/common/validators/rules";
import CForm from "@/common/components/CForm.vue";
import CDeleteConfirm from "@/common/components/CDeleteConfirm.vue";
import CMarkdown from "@/common/components/CMarkdownWrap.vue";

@Component({ components: { CForm, CDeleteConfirm, CMarkdown } })
export default class NewsForm extends Vue {
    newsStore = new NewsApi();
    validators = { required, maxLength };
    openDialog = false;
    item: News = {} as News;

    open(item: News) {
        this.item = item;
        this.openDialog = true;
        // this.$nextTick(() => (this.$refs.form as CForm).resetValidation())
    }

    close() {
        this.openDialog = false;
    }

    @Emit("change")
    save() {
        if (!(this.$refs.form as any).validate()) {
            return;
        }

        return this.newsStore.save({ news: this.item }).then(() => this.close());
    }

    // @Emit('delete')
    // deleteConfirmed() {
    //     return this.newsStore.delete({ teamId: this.team.id })
    //         .then(() => this.close())
    // }
}
